.rfc {
  h2, h3 {
    margin-top: $spacing-unit*2;
  }
  h1:first-child {
    margin-bottom: 0;
    + h2 {
      margin-top: $spacing-unit/2;
    }
  }
}

.rfc-sidebar {
  padding: $spacing-unit*2;
  background: #f2f2f2;
  border-radius: $border-radius;
  margin-top: $spacing-unit;

  @include screen-sm-down {
    padding: $spacing-unit;
  }

  h2 {
    margin: 0;
  }

  .status {
    @include font-size($txt-xs);
    color: $dark-grey;
  }

  dt {
    margin-top: $spacing-unit;
    font-weight: bold;
  }

  dd {
    @include font-size($txt-xs);

    strong {
      color: $dark-grey;
    }
  }
}
