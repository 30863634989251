
.tutorial {

  aside {

    position: relative;
    width: auto;
    margin-top: 0;

    p {
      margin-top: -$spacing-unit/2;
      margin-bottom: 0;
    }

    nav {

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        ul {
          margin-top: $spacing-unit/2;
          margin-left: $spacing-unit;
          margin-right: $spacing-unit;
          margin-bottom: $spacing-unit;

          li {
            border-top: none;

            a {
              padding-top: $spacing-unit/4;
              padding-bottom: $spacing-unit/4;
            }
          }
        }

        & > li {
          border-top: 1px dashed #ccc;
        }
      }

      li {
        padding: 0;

        &.normal {
          font-weight: normal;
        }

        &.selected {
          font-weight: bold;
        }
      }

      a {
        text-decoration: none;
        display: block;
        padding-top: $spacing-unit/2;
        padding-bottom: $spacing-unit/2;
        color: $accent-color-5;
        em {
          color: #999;
        }
        del {
          color: #900;
          opacity: .5;
        }
      }
    }
  }

  h1 {
    &:first-of-type {
      border-bottom: 1px dashed #ccc;
      margin-bottom: $spacing-unit+1.5 !important;
      margin-top: 0;
      padding-bottom: $spacing-unit;
    }
  }

  p, pre, table, dl, ul {
    & + a[name] + h2,
    & + a[name] + h3,
    & + h2,
    & + h3,
    & + h4 {
      margin-top: $spacing-unit*2;
    }
  }

  .main {
    ul {
      a {
        em {
          color: #999;
        }
        del {
          color: #900;
          opacity: .5;
        }
      }
    }
  }


  pre {
    .input {
      color: #777;
    }

    .output {
      font-style: italic;
      color: #666;
    }

    .code {
      color: #333;
    }
  }
}