
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

//
// 1. Correct `color` not being inherited in IE 8/9.
// 2. Remove padding so people aren't caught out if they zero out fieldsets.
//

legend {
  border: 0; // 1
  padding: 0; // 2
}

button {
  border: 0;
}

input,
button {
  line-height: normal;
}

select {
  background: none;
}

label {
  cursor: pointer;
}



textarea {
  overflow: auto; // 1
  vertical-align: top; // 2
  resize: none;
  padding: 10px 0 10px 10px;
}


//
// 1. Correct font family not being inherited in all browsers.
// 2. Correct font size not being inherited in all browsers.
// 3. Address margins set differently in Firefox 4+, Safari 5, and Chrome.
//

button,
input,
select,
textarea {
  font-family: inherit; // 1
  font-size: 100%; // 2
  margin: 0; // 3
  outline: none;
  -webkit-font-smoothing: antialiased;
}


//
// Address inconsistent `text-transform` inheritance for `button` and `select`.
// All other form control elements do not inherit `text-transform` values.
// Correct `button` style inheritance in Chrome, Safari 5+, and IE 8+.
// Correct `select` style inheritance in Firefox 4+ and Opera.
//

button,
select {
  text-transform: none;
}

// 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
//    and `video` controls.
// 2. Correct inability to style clickable `input` types in iOS.
// 3. Improve usability and consistency of cursor style between image-type
//    `input` and others.
//


button,
html input[type="button"], // 1
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button; // 2
  cursor: pointer; // 3
}

//
// Re-set default cursor for disabled elements.
//

button[disabled],
html input[disabled] {
  cursor: default;
}

//
// 1. Address box sizing set to `content-box` in IE 8/9.
// 2. Remove excess padding in IE 8/9.
//

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box; // 1
  padding: 0; // 2
  vertical-align: baseline;
}

//
// 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
// 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
//    (include `-moz` to future-proof).
//

input[type="search"] {
  -webkit-appearance: textfield; // 1
}

//
// Remove inner padding and search cancel button in Safari 5 and Chrome
// on OS X.
//

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

//
// Remove inner padding and border in Firefox 4+.
//

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}


// ==========================================================================
// Tables
// ==========================================================================

//
// Remove most spacing between table cells.
//











///********** Form stuff *****************/

::-webkit-input-placeholder {
  color: #999;
  -webkit-transition: color 0.2s linear;
}
input:focus::-webkit-input-placeholder {
  color: transparent;
}
textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

::-moz-placeholder {
  color: #999;
  opacity: 1; // need this hack for color to work on FF
  -moz-transition: color 0.2s linear;
}
input:focus::-moz-placeholder {
  color: transparent;
  opacity: 1; // need this hack for color to work on FF
}
textarea:focus::-moz-placeholder {
  color: transparent;
}

:-ms-input-placeholder {
  color: #999;
  transition: color 0.2s linear;
}
input:focus::-placeholder {
  color: transparent;
}
textarea:focus::-placeholder {
  color: transparent;
}


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: textfield;
}

input[type=number] {
-moz-appearance: textfield;
}

input[type=date]::-webkit-inner-spin-button {
  display: none;
}

input[type="search"] {
  -webkit-appearance: none;
}
