

*,
*:before,
*:after {
  box-sizing: border-box;
}

main {
  display: block;
}

dl,
dt,
dd {
  margin: 0;
  padding: 0;
}

//
// Correct `inline-block` display not defined in IE 8/9.
//

audio,
canvas,
video {
  display: inline-block;
}



// ==========================================================================
// Base
// ==========================================================================


// Prevent iOS text size adjust after orientation change, without disabling
//    user zoom.

html {
  -webkit-text-size-adjust: 100%; // 2
  -ms-text-size-adjust: 100%; // 2
  -webkit-font-smoothing: antialiased;
  word-wrap: break-word;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}


body {
  margin: 0;
  line-height: $site-line-height;
  min-height: 100%;
  position: relative;
}



//
// Address style set to `bolder` in Firefox 4+, Safari 5, and Chrome.
//

b,
strong {
  font-weight: bold;
}

//
// Address styling not present in Safari 5 and Chrome.
//

dfn {
  font-style: italic;
}


//
// Correct font family set oddly in Safari 5 and Chrome.
//

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}

//
// Improve readability of pre-formatted text in all browsers.
//

pre {
  white-space: pre-wrap;
}

//
// Set consistent quote types.
//

q {
  quotes: "\201C" "\201D" "\2018" "\2019";
}

//
// Address inconsistent and variable font size in all browsers.
//

small {
  font-size: 80%;
}

//
// Prevent `sub` and `sup` affecting `line-height` in all browsers.
//

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}



img {
  // max-width: 100%;
  // height: auto;
  vertical-align: middle;
}



table {
  border-collapse: collapse;
  border-spacing: 0;
}






figure {
  margin: 0;
  a {
    display: block;
    max-width: 100%;
  }
}

a img {
  border: none;
}



abbr[title] {
  border-bottom: 1px dotted;
}


//
// Prevent modern browsers from displaying `audio` without controls.
// Remove excess height in iOS 5 devices.
//

audio:not([controls]) {
  display: none;
  height: 0;
}
