.flake-it {
  overflow: hidden;
  position: relative;
}

.snowflake {
  opacity: .7;
  color: #fff;
  font-size: 1em;
  font-family: Arial;
  text-shadow: 0 0 1px rgba(0, 0, 0, .5);
}

@keyframes snowflakes-fall {
  0%   { top: -10% }
  100% { top: 100% }
}

@keyframes snowflakes-shake {
  0%   { transform: translateX( 0px) }
  50%  { transform: translateX(80px) }
  100% { transform: translateX( 0px) }
}

.snowflake {
  position: absolute;
  top: -10%;
  // z-index: 0;
  user-select: none;
  cursor: default;
  animation-name: snowflakes-fall, snowflakes-shake;
  animation-duration: 10s, 3s;
  animation-timing-function: linear, ease-in-out;
  animation-iteration-count: infinite, infinite;
  animation-play-state: running, running;

  @include screen-sm-down {
    font-size: .8em;
  }
}

@mixin gen-flakes($num: 10) {
  $d: 100 / $num;

  @for $i from 0 through $num {
    $diff: 0;

    @if $i == 0 {
      $diff: 1;
    }

    .snowflake:nth-of-type(#{$i}) {
      opacity: random(100)/100;
      left: #{($d * $i) +  $diff}#{%};
      animation-delay: (random(100) / 10) + s, (random(100) / 10) + s;
    }
  }
}

@include gen-flakes(30);


// .snowflake:nth-of-type(0) {
//   left:1%;
//   animation-delay: 0s, 0s;
// }

// .snowflake:nth-of-type(1) {
//   left:10%;
//   animation-delay: 1s, 1s;
// }

// .snowflake:nth-of-type(2) {
//   left:20%;
//   animation-delay: 6s, .5s;
// }

// .snowflake:nth-of-type(3) {
//   left:30%;
//   animation-delay:4s, 2s;
// }

// .snowflake:nth-of-type(4) {
//   left:40%;
//   animation-delay: 2s, 2s
// }

// .snowflake:nth-of-type(5) {
//   left:50%;
//   animation-delay: 8s, 3s
// }

// .snowflake:nth-of-type(6) {
//   left:60%;
//   animation-delay: 6s, 2s
// }

// .snowflake:nth-of-type(7) {
//   left:70%;
//   animation-delay: 2.5s, 1s
// }

// .snowflake:nth-of-type(8) {
//   left:80%;
//   animation-delay: 1s, 0s
// }

// .snowflake:nth-of-type(9) {
//   left:90%;
//   animation-delay: 3s, 1.5s
// }
// </style>
/* <div class="snowflakes" aria-hidden="true">
  <div class="snowflake">
  ❄
  </div>
  <div class="snowflake">
  ❅
  </div>
  <div class="snowflake">
  ❆
  </div>
  <div class="snowflake">
  ❄
  </div>
  <div class="snowflake">
  ❅
  </div>
  <div class="snowflake">
  ❆
  </div>
  <div class="snowflake">
  ❄
  </div>
  <div class="snowflake">
  ❅
  </div>
  <div class="snowflake">
  ❆
  </div>
  <div class="snowflake">
  ❄
  </div>
</div> */
