@mixin make-row() {
  @include clearfix();
  margin-left: -$grid-gutter-width / 2;
  margin-right: -$grid-gutter-width / 2;
  font-size: 0;
  @media (max-width: $screen-xs) {
      margin-left: -$grid-gutter-width / 4;
      margin-right: -$grid-gutter-width / 4;
  }
}

@mixin make-grid-columns($i: 1, $list: ".col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}") {
  @for $i from (1 + 1) through $grid-column-count {
  $list: "#{$list}, .col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}";
  }
  #{$list} {
    @include font-size($base-font-size);

    position: relative;
    // Prevent columns from collapsing when empty
    min-height: 1px;
    // Inner gutter via padding
    padding-left:  ($grid-gutter-width / 2);
    padding-right: ($grid-gutter-width / 2);
    display: inline-block;
    width: 100%;
    vertical-align: top;
    text-align: left;
    @media (max-width: $screen-xs) {
      padding-left:  ($grid-gutter-width / 4);
      padding-right: ($grid-gutter-width / 4);
    }
  }
}



@mixin calc-grid-column($index, $class, $type) {
  @if ($type == width) and ($index > 0) {
    .col-#{$class}-#{$index} {
      width: percentage(($index / $grid-column-count));
    }
  }
  @if ($type == push) and ($index > 0) {
    .col-#{$class}-push-#{$index} {
      left: percentage(($index / $grid-column-count));
    }
  }
  @if ($type == push) and ($index == 0) {
    .col-#{$class}-push-0 {
      left: auto;
    }
  }
  @if ($type == pull) and ($index > 0) {
    .col-#{$class}-pull-#{$index} {
      right: percentage(($index / $grid-column-count));
    }
  }
  @if ($type == pull) and ($index == 0) {
    .col-#{$class}-pull-0 {
      right: auto;
    }
  }
  @if ($type == offset) {
    .col-#{$class}-offset-#{$index} {
      margin-left: percentage(($index / $grid-column-count));
    }
  }
}

// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin loop-grid-columns($columns, $class, $type) {
  @for $i from 0 through $columns {
    @include calc-grid-column($i, $class, $type);
  }
}


// Create grid for specific class
@mixin make-grid($class) {
  @include loop-grid-columns($grid-column-count, $class, width);

  @if ($class != xs) {
    @if $options__grid__pull-classes == true {
      @include loop-grid-columns($grid-column-count, $class, pull);
    }
    @if $options__grid__push-classes == true {
      @include loop-grid-columns($grid-column-count, $class, push);
    }
    @if $options__grid__offset-classes == true {
      @include loop-grid-columns($grid-column-count, $class, offset);
    }
  }
}


@mixin columns($number) {
  column-count: $number;
  &--screen-sm {
    @media (max-width: $screen-sm) {
      column-count: $number / 2;
    }
  }
  &--screen-xs {
    @media (max-width: $screen-xs) {
      column-count: $number / 3;
    }
  }
}