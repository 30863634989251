#action-panel {
  position: absolute;
  top: 0%;
  right: 250px;
  padding: 0 0;
  opacity: .4;
  transition: opacity .2s ease-in;
  z-index: 10;
  // transform: rotate(-90deg);
  a {
    margin: 0;
    text-decoration: none;
    display: block;
    padding: 2px $spacing-unit/2;
    @include font-size($txt-xxs);
    background: $subnav-bg-color;
    border-radius: 0 0 $border-radius $border-radius;
    color: $light-grey-text;
  }

  &:hover {
    opacity: 1;
  }
}

.source-contributors {
  list-style: none;
  margin: $spacing-unit 0;
  padding: 0;
  @include font-size($txt-xs);
  opacity: .6;
  columns: 5;
  transition: opacity .2s ease-in;

  @include screen-md-down() {
    columns: 4;
  }

  @include screen-sm-down() {
    columns: 3;
  }

  @include screen-xs-down() {
    columns: 2;
  }

  &.narrow {
    columns: 4;
  }

  &:hover {
    opacity: 1;
  }

  a {
    display: block;
    margin-right: $spacing-unit;
    padding-top: $spacing-unit/2;
    padding-bottom: $spacing-unit/2;
  }
}

.dimmed {
  opacity: .5;
  transition: opacity .2s ease-in;
  &:hover {
    opacity: 1;
  }
}

.avatar {
  a {
    @include font-size($txt-xxs);
    display: block;
    text-decoration: none;
    color: #666;
    transition: color .2s ease-in;

    &:hover {
      color: $default-link-color;
    }
  }
  small {
    color: $grey-text;
  }
  &-small {
    img {
      margin-right: $spacing-unit/2;
      width: 24px;
      height: 24px;
    }
  }
}

.chart {
  height: 300px;
  margin-bottom: $spacing-unit;
}

.github-stats {
}

.grayscale {
  // filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale"); /* Firefox 10+, Firefox on Android */
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
  -moz-filter: grayscale(100%);
  &:hover {
    filter: none;
    -webkit-filter: none;
  }
}

.icon-button {
  text-decoration: none;

  &:before {
    font-family: FontAwesome;
    display: inline-block;
    vertical-align: middle;
    margin-right: 6px;
    @include font-size($txt-xl);
  }

  &.google-plus {
    &:before {
      content: "\f0d4";
    }
  }
}

footer {
  .icon-button {
    @include font-size($txt-xs);
    vertical-align: middle;
    color: white;
    opacity: .4;
    transition: opacity .2s ease-in;

    &::before {
      @include font-size($txt-md);
      color: white;
      vertical-align: middle;
      line-height: 1;
    }

    &:hover {
      opacity: 1;
    }
  }
}

#front-news-list {
  position: relative;
  @include font-size($txt-xs);
  @media screen and (min-width: $screen-md+1) {
    max-height: 260px;
    overflow: auto;
    padding-right: $spacing-unit;
  }
}

#google-search {
  input {
    position: relative;

    &:focus, .content {
      border-color: #0087cc;
    }
  }
}

#google-search-result {
  list-style: none;
  margin: 0;
  padding: 0;

  &.populated {
    margin-top: $spacing-unit/2;
  }

  li {
    border-bottom: 1px dashed $light-grey-text;
    padding: $spacing-unit/2 0;

    a {
      text-decoration: none;
    }

    .title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .snippet {
      @include font-size($txt-xxs);
      color: $dark-grey;
    }

    .ref {
      @include font-size($txt-xxs);
      margin-bottom: $spacing-unit/2;
      color: #444;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &.insite {
    li {
      padding: $spacing-unit 0;

      .snippet {
        @include font-size($txt-xs);
      }

      .ref {
        color: #aaa;
      }
    }
  }
}

.google-search-result-nav {
  list-style: none;
  padding: 0;
  margin-top: 0;
  border-top: 1px dashed $grey;
  padding-top: $spacing-unit;
  text-align: center;
  li {
    display: inline-block;
    margin: 0 2px;
    a, span {
      display: block;
      text-align: center;
      width: 54px;
      border-radius: $border-radius;
      background: $accent-color-1;
      color: white;
      text-decoration: none;
      padding: $spacing-unit/3 $spacing-unit/2;
      &:hover {
        background: #404040;
      }
    }
    span {
      background: #404040;
    }
  }
}

h2.header, h3.header {
  border-bottom: 1px dashed #ccc;
  margin-bottom: $spacing-unit+1.5;
  padding-bottom: $spacing-unit;
  &.big {
    @include font-size($txt-xxl);
  }
}

.table {
  display: table;
  widows: 100%;
}
.td {
  width: auto;
  display: table-cell;
  vertical-align: top;
}
.nodecoration {
  text-decoration: none;
  a {
    text-decoration: none;
  }
}
.no-margin-top {
  margin-top: 0;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.no-padding-top {
  padding-top: 0;
}

.text-right {
  text-align: right;

  @include screen-xs-down {
    text-align: left;
  }
}

.text-left {
  text-align: left;
}

.text-bold {
  font-weight: bold;
}

.text-em {
  font-variant: italic;
}

.text-small {
  @include font-size($txt-xs);
}

.scrollable {
  overflow-x: auto;
}

.hidden {
  display: none;
}

.muted {
  color: $dark-grey;
}

[class^='col-md-'] {
  @media (max-width: $screen-md) {
    &.text-right {
      text-align: left;
    }
  }
}

[class^='col-sm-'] {
  @media (max-width: $screen-sm) {
    &.text-right {
      text-align: left;
    }
  }
}


.margin-bottom {
  margin-bottom: $spacing-unit;
  &-imp {
    margin-bottom: $spacing-unit !important;
  }
}

.logo-download {
  img {
    display: inline-block;
    margin-right: $spacing-unit;
    margin-bottom: $spacing-unit/2;
    max-height: 57px;
    width: auto;
    padding: $spacing-unit/2;
    vertical-align: middle;
    &.negative {
      background: #404040;
    }
  }
  a {
    vertical-align: middle;
    @include font-size($txt-xs);
    text-decoration: none;
    margin-right: $spacing-unit/4;
    margin-bottom: $spacing-unit/2;
    display: inline-block;
    background: $accent-color-1;
    color: white;
    padding: $spacing-unit/2 $spacing-unit;
    border-radius: $border-radius;
    transition: background .2s ease-in;
    &:hover {
      background: $dark-grey;
    }
    &:last-child {
      margin-right: none;
    }
  }
}

#inline-site-search {
  .control-group {
    display: inline-block;
    &.search-field {
      width: 75%;
      margin-right: 1%;
    }
    &.submit-button {
      width: 24%;
      input {
        width: 100%;
      }
    }
  }
}

span.compat {
  display: inline-block;
  &:before {
    font-family: FontAwesome;
    @include font-size($txt-lg);
  }

  &--1,
  &--01,
  &--02 {
    &:before {
      content: '\f10c';
      color: lighten($dark-grey, 15%);
    }
  }

  &--01 {
    &:before {
      color: lighten($dark-grey, 25%);
    }
  }

  &--02 {
    &:before {
      color: lighten($dark-grey, 35%);
    }
  }

  &-1, &-2 {
    &:before {
      content: '\f00c';
    }
  }
  &-2 {
    &:before {
      color: #48af69;
    }
  }
  &-1 {
    &:before {
      color: #dbdc83;
    }
  }
  &-0 {
    &:before {
      content: '\f00d';
      color: #930;
    }
  }
}

.compat-desc {
  div {
    display: inline-block;
    margin-right: $spacing-unit;
  }
}
