
table {
  border-collapse: collapse;

  a {
    &:hover {
      .compat {
        opacity: .7;
      }
    }
  }

  &.compat,
  &.pikefarm {
    width: 100%;
    border: 1px solid #eee;
    margin: $spacing-unit 0;

    th, td {
      text-align: center;
      vertical-align: middle;
      border-right: 1px dotted #ccc;
      border-bottom: 1px dotted #ccc;
      padding: $spacing-unit/2 $spacing-unit/2;
    }

    th {
      background: $light-grey;
      color: $dark-grey;
      @include font-size($txt-xs);
    }
  }

  &.pikefarm {
    margin-top: 0;

    tbody,
    tfoot {
      font-size: smaller;
      color: $dark-grey;
    }

    thead {
      th {
        background: lighten($grey, 5%);
        border-bottom-color: $grey;
      }
    }

    th, td {
      border-bottom: 1px solid lighten($grey, 5%);
    }

    &.build-client {
      th,
      td {
        text-align: left;
      }
    }

    tbody {
      small {
        color: $grey-text;
      }

      tr {
        &:nth-child(2n+1) {
          // background: lighten($grey, 8%);

          td {
            &:nth-child(2n+1) {
              background: lighten($grey, 12%);;
            }
          }
        }
        &:nth-child(2n+2) {
          background: lighten($grey, 11%);

          td {
            &:nth-child(2n+1) {
              background: lighten($grey, 9%);
            }
          }
        }
      }
    }
  }

  &.pikefarm-overview {
    font-size: smaller;
    border-color: $grey;

    .desc-group {
      margin-top: $spacing-unit;
    }

    .desc {
      text-align: left;
      font-weight: normal;
      font-size: 11px;
      line-height: 1;
      margin-bottom: $spacing-unit/2;

      &:last-of-type {
        margin-bottom: $spacing-unit;
      }

      &::after {
        display: table;
        content: ' ';
        clear: both;
      }

      .compat {
        margin-right: 3px;
        margin-left: -4px;
        float: left;
        margin-top: -4px;

        &:before {
          display: block;
          width: 24px;
          font-size: 20px;
          text-align: center;
        }
      }

      .compat,
      .text {
        vertical-align: middle;
      }
    }

    tbody {
      tr {
        border-bottom: 1px dotted $grey;
      }
    }

    col, colgroup {
      border-color: $grey;
    }

    th,
    td {
      padding: $spacing-unit/2;
      text-align: center;
    }

    tr {
      > *:first-child {
        text-align: right;
        font-size: smaller;
      }

      > *:last-child {
        text-align: left;
        font-size: smaller;
      }

      &.official {
        border-bottom-style: solid;

        td {
          background: lighten(#E0F2FF, 0%);
        }

        > *:first-child {
          background: lighten(#E0F2FF, 3%);
        }

        > *:last-child {
          background: lighten(#E0F2FF, 3%);
        }
      }
    }

    thead {
      th {
        position: relative;
        vertical-align: bottom;
      }
    }

    span {
      &.build-date {
        display: inline-block;
        font-size: smaller;
        transform-origin: left center 0;
        position: absolute;
        bottom: 0;
        transform: rotate(-90deg);
        white-space: nowrap;
      }
    }
  }
}

.pikefarm-collected-files {
  columns: 2 auto;
  column-gap: $spacing-unit;

  @include screen-xs-up {
    columns: 3 auto;
  }

  @include screen-md-up {
    columns: 4 auto;
  }

  br {
    display: inline-block;
  }
}

