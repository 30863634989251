@mixin screen-xs-up {
  @media (min-width: $screen-xs+1) {
    @content;
  }
}
@mixin screen-xs-down {
  @media (max-width: $screen-xs) {
    @content;
  }
}

@mixin screen-sm-up {
  @media (min-width: $screen-sm+1) {
    @content;
  }
}
@mixin screen-sm-down {
  @media (max-width: $screen-sm) {
    @content;
  }
}

@mixin screen-md-up {
  @media (min-width: $screen-md+1) {
    @content;
  }
}
@mixin screen-md-down {
  @media (max-width: $screen-md) {
    @content;
  }
}

@mixin screen-lg-up {
  @media (min-width: $screen-lg+1) {
    @content;
  }
}
@mixin screen-lg-down {
  @media (max-width: $screen-lg) {
    @content;
  }
}

@mixin screen-sp-down {
  @media (max-width: $screen-sp) {
    @content;
  }
}


@mixin retina {
  @media
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    @content;
  }
}