aside {
  menu {
    .bare-list {
      margin: (-$site-padding-width/2) (-$site-padding-width);
    }

    a {
      text-decoration: none;
      display: block;
      padding: $site-padding-width/3 $site-padding-width/2;
      border-bottom: 1px dashed $grey;
    }

    li:first-child {
      a {
        border-top: 1px dashed $grey;
      }
    }

    .is-current-page {
      font-weight: bold;
    }

    .normal {
      font-weight: normal;
    }
  }
}

@include screen-sm-down {
  #side-menu-top-container {

    .tmc-wrapper {
      border-top: 1px solid $grey;
      border-bottom: 1px solid $grey;
    }

    menu {
      padding-left: 0;
    }

    ul {
      list-style: none;
      padding: 0;

      a {
        display: block;
        padding: $site-padding-width/2;
        border-bottom: 1px dashed $grey;
        text-decoration: none;
      }

      > li:last-child {
        a {
          border-bottom: 0;
        }
      }
    }

    .m-container {
      display: none;
    }

    .site-wrap {
      padding-top: $site-padding-width/2;
      padding-bottom: $site-padding-width/2;
    }

    .burger {
      position: relative;
      span {
        // vertical-align: middle;
        display: inline-block;
      }
      &.on {
        .the-burger {
          transform: rotate(90deg);
          top: 0;
        }
      }
      .the-burger {
        position: absolute;
        left: 0;
        top: -1px;
        bottom: 0;
        transform: rotate(0deg);
        transition: transform .2s ease-out;

        img {
          width: 16px;
          height: 16px;
        }
      }
      .the-text {
        margin-left: $spacing-unit*1.5;
        @include font-size($txt-xxs);
      }
    }
  }
}
