
a.roxen-linkbrowser-anchor {
  text-decoration: none;

  &:before, &:after {
    content: none !important;
    margin: 0;
  }
}

.component-spacing {
  margin-top: $spacing-unit*2;
}

.component {
  position: relative;
  margin-bottom: $spacing-unit;

  &:last-child {
    margin-bottom: 0;
  }
  h1, h2, h3 {
    margin-top: 0;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

.header-component {
  border-bottom: 1px dashed #ccc;
  margin-bottom: $spacing-unit+1.5;
  h1 {
    margin: 0 0 $spacing-unit;
  }
}

.version-component {
  h3 {
    vertical-align: middle;
    line-height: 1;
    padding-left: 36px;

    i {
      &.fa {
        margin-right: $spacing-unit/2;
        color: white;
        background: $accent-color-1;
        padding: $spacing-unit/1.5 $spacing-unit;
        border-radius: $border-radius;
      }
    }
    a {
      text-decoration: none;
      display: block;

      &:before {
        font-family: FontAwesome;
        content: '\f019';
        margin-top: -4px;
        margin-right: $spacing-unit/2;
        // margin-bottom: $spacing-unit/2;
        // font-size: 3.2rem;
        vertical-align: middle;
        // float: left;
        display: inline-block;
        background: $accent-color-1;
        color: $white;
        padding: $spacing-unit/2;
        padding-bottom: $spacing-unit/2.5;
        // line-height: 1;
        border-radius: $border-radius;
        margin-left: -36px;
      }

      &:hover:before {
        background: darken($accent-color-1, 15%);
      }

      &:after {
        display: table;
        clear: both;
        content: ' ';
      }
    }
  }
}

.picture-component {
  &.variant-0, &.variant-1, &.variant-4, &.variant-5, &.variant-6 {
    @include clearfix();
  }

  // h2 + h3 {
  //   margin-top: -($spacing-unit);
  // }

  p.last {
    margin-bottom: 0;
  }

  + .picture-component {
    h2:first-child {
      margin-top: $spacing-unit*2;
    }
  }

  figure {
    margin-bottom: $spacing-unit;
    position: relative;

    figcaption {
      margin: $spacing-unit/2 0;
      @include font-size($txt-xs);
      color: #555;
    }

    img {
      width: auto;
      max-width: 100%;
      height: auto;
    }
  }

  .align {
    &--left, &--right {
      figure {
        width: 40%;
        margin-bottom: $spacing-unit;
      }
    }

    &--left {
      figure {
        float: left;
        margin-right: $spacing-unit*2;
      }
    }

    &--right {
      figure {
        float: right;
        margin-left: $spacing-unit*2;
      }
    }
  }
}

.break-component, .divider {
  hr {
    margin: $spacing-unit*2 0;
    background: transparent;
    height: 1px;
    border: none;
    border-top: 1px dashed #ccc;
  }
  &.narrow-margin {
    hr {
      margin: $spacing-unit 0;
    }
  }
}

@mixin button() {
  padding-left: $spacing-unit;
  padding-right: $spacing-unit;
  padding-top: $spacing-unit/1.5;
  padding-bottom: $spacing-unit/1.5;
  line-height: 1.2;
  border-radius: $border-radius;
  text-decoration: none;
  display: inline-block;
}

/*
  variant-0: Default link
  variant-1: Button (inline-block)
  variant-2: Button (block)
  variant-3: Button (outlined inline-block)
  variant-4: Button (outlined block)
  variant-5: Read more
*/
.href-component {
  &.variant-1, &.variant-2 {
    a {
      @include button();
      color: white;
      background: $default-link-color;
    }
  }

  &.variant-3, &.variant-4 {
    a {
      @include button();
      border: 1px solid $default-link-color;
    }
  }

  &.variant-2, &.variant-4 {
    a {
      display: block;
      text-align: center;
    }
  }

  &.variant-5 {
    a {
      text-decoration: none;
      &:after {
        content: '\f18e';
        font-family: FontAwesome;
        margin-left: 6px;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}

.href-list-component {
  .header {
    margin-bottom: $spacing-unit/2;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    a {
      display: inline-block;
      text-decoration:none;
      &:before {
        font-family: FontAwesome;
        content: '\f138';
        display: inline-block;
        margin-right: $spacing-unit/2;
      }
    }
  }
}

.file-component {
  a {
    text-decoration: none;
    display: inline-block;
    .filename {
      font-weight: bold;
    }
    .metadata {
      display: block;
      color: #888;
      @include font-size($txt-xxs);
    }
    &:before {
      font-family: FontAwesome;
      content: '\f15b';
      margin-right: $spacing-unit/2;
    }
  }
}


main {
  .group-box {
    padding: $spacing-unit;
    background: $light-bg-color;
    border-radius: $border-radius;
  }
}
