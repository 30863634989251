@mixin root-size() {

  // set breakpoints
  $screen-sizes: $screen-md, $screen-sm, $screen-xs;
  // set base font-size
  font-size: nth($root-sizing-unit,1);

  // loop through the screen sizes list.
  @each $scr in $screen-sizes {

    //get current index of the list
    $index: index($screen-sizes, $scr);

    // calculate font size reduction value by multiplying the
    // coefficient by the current index calue
    $reduction: (($root-sizing-coefficient * $index));

    // increase the coefficient before looping again.
    $root-sizing-coefficient: $root-sizing-coefficient + 0.5 !global;

    // get the current index
    @media (max-width: $scr) {
      font-size: $root-sizing-unit - $reduction;
    }
  }
}

@mixin clearfix() {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
