html {
  @include root-size;

  &.touch-screen {
    cursor: pointer; // fixes ios bug for document root click handlers
  }
}

@if ($options__use-show-breakpoints) {
  // allows javaScript function: PIKE.checkCurrentMediaQuery to detect which screen size we are in.
  .show-bp {
    @include positioning(absolute,-999999px,auto,auto,-9999999px);
    @include screen-lg-up {
      width: 1px;
    }
    @media (max-width: $screen-lg) {
      width: 2px;
    }
    @media (max-width: $screen-md) {
      width: 3px;
    }
    @media (max-width: $screen-sm) {
      width: 4px;
    }
    @media (max-width: $screen-xs) {
      width: 5px;
    }
  }
}