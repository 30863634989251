form {
  margin: 0;
}

fieldset {
  margin: 0;
  margin-bottom: $spacing-unit;
  padding: $spacing-unit*1.5;
  border: 1px solid $grey;
  border-radius: $border-radius;

  legend {
    padding: 0 $spacing-unit;
    color: $dark-grey;
    @include font-size($txt-xxs);
    font-weight: bold;
  }
}

$ok-field-border-color: #6AB595;

.control-group {
  margin-bottom: $spacing-unit;
  position: relative;

  label {
    display: block;
    margin-bottom: $spacing-unit/3;

    input + span {
      margin-left: $spacing-unit/3;
    }
  }

  input[type='text'],
  input[type='search'],
  input[type='password'],
  input[type='url'],
  input[type='email'],
  input[type~='date'],
  input[type~='datetime'],
  input[type~='datetime-local'],
  input[type='number'],
  textarea, select {
    width: 100%;
    border-radius: $border-radius;
    border: 1px solid $light-grey-text;
    padding: $spacing-unit;
    font-size: 100%;
    outline: none;
    font-family: inherit;

    &.tight {
      padding: $spacing-unit/2;
    }

    &:disabled {
      color: #ccc;
      background: #f6f6f6;
    }

    &:focus {
      border-color: $accent-color-1;
    }
  }

  input[type='file'] {
    &:focus {
      border: 1px dashed $accent-color-1;
    }
  }

  input,
  textarea,
  select {
    &:required {
      border-color: $accent-color-1;

      &:valid {
        border-color: $ok-field-border-color;
      }
    }
  }
}

textarea {
  resize: vertical;
}

input[type='submit'],
input[type='button'],
input[type='reset'],
button {
  border: 1px solid transparent;
  padding: $spacing-unit;
  border-radius: $border-radius;
  background: $accent-color-1;
  color: white;
  transition: background .2s ease-in;
  outline: none;
  &:active {
    box-shadow: 2px 2px 2px rgba(0, 0, 0, .3) inset ;
  }
  &:focus {
    border: 1px dashed #333;
  }
  &:disabled {
    background: $grey;
  }
}
