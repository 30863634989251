
.notify {
  background: #fdf0d5;
  color: #7F7234;
  padding: $spacing-unit*2 $spacing-unit*2;
  margin: $spacing-unit 0;
  border-radius: $border-radius;

  &.info {
    background: #d8edf7;
    color: #5b6167;
  }

  &.ok {
    background: #EAFFEE;
    color: darken(#EAFFEE, 70%);
  }

  &.error {
    background: #FFEAEB;
    color: darken(#FFEAEB, 70%);
  }

  *:first-child {
    margin-top: 0;
  }
  *:last-child {
    margin-bottom: 0;
  }

  @media (max-width: $screen-sm) {
    padding: $spacing-unit;
  }
}
