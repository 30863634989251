
body {
  @include font-size($base-font-size);
  color: $main-text-color;
  font-family: $font-family;
  // background: $dark-bg-color;
  background: white;
  overflow-y: scroll;
  padding-bottom: $spacing-unit*3;
  -webkit-font-smoothing: antialiased;
}

svg, tspan, svg text {
  color: $main-text-color;
  font-family: $font-family;
}

main {
  display: block;
}

.head-msg {
  background: #1a1a1a;
  color: $accent-color-3;
  @include font-size($txt-xs);

  .inner {
    div {
      > *:last-child {
        margin-bottom: 0;
      }
    }
    .component {
      &*:last-child {
        *:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.section {
  padding: $spacing-unit*2 0;

  .section {
    padding: 0;
  }

  &.light-blue {
    background: #54A0E5;
  }

  &.light-grey {
    background: $light-grey;
  }

  &.grey {
    background: #666;

    svg {
      path[stroke='#D8D8D8'] {
        stroke: #777;
      }
    }

    h1, h2, h3, h4 {
      color: #bbb;
    }

    .avatar {
      a {
        color: #aaa;
      }

      img {
        opacity: .4;
        transition: opacity .2s ease-in;
      }

      &:hover {
        img {
          opacity: 1;
        }
      }
    }

    a {
      color: $grey-link-color;
      &:hover {
        color: white;
      }
    }

    .divider {
      hr {
        border-color: #777;
      }
    }
  }
}

h1, h2, h3, .header {
  margin: $spacing-unit 0;
  line-height: $site-line-height - 0.2; // reduce line-height a little for headlines.
  color: $complementary-color;
}

[class^='col-'] {
  & > h1 {
    margin-top: 0;
    border-bottom: 1px dashed #ccc;
    margin-bottom: $spacing-unit*2 !important;
    padding-bottom: $spacing-unit;
  }
}
h1, .header.h1 {
  @include font-size($h1-font-size);
}
h2, .header.h2 {
  @include font-size($h2-font-size);
}
h3, .header.h3 {
  @include font-size($h3-font-size);
}
h4, .header.h4 {
  @include font-size($h4-font-size);
}

p {
  margin: 0 0 $spacing-unit 0;
}
a {
  color: $default-link-color;
  &:hover,
  &:focus {
    color: darken($default-link-color, 15%);
    outline: none;
  }
  &:active {
      outline: none;
  }
}

img {
  border-radius: $border-radius;
}

.breadcrumbs {
  background: $breadcrumbs-bg-color;
  .site-wrap {
    padding: $spacing-unit/2 $spacing-unit;
  }

  a {
    text-decoration: none;
    @include font-size($txt-xxs);
    &:after {
      content: '›';
      display: inline-block;
      color: $dark-grey;
      margin: 0 $spacing-unit/2;
      @include font-size($txt-xs);
    }
    &:last-child {
      color: $dark-grey;
      &:after {
        content: none;
        margin: 0;
      }
    }
  }
}

.page-content {
  background: white;
  position: relative;
  flex: 1;
}

.site-wrap {
  max-width: $site-wrap-width;
  margin: 0 auto;
}

.site-padding {
  padding: $site-padding-width;
}

.site-padding-sides {
  padding-left: $site-padding-width;
  padding-right: $site-padding-width;
}

#burger {
  display: none;

  @include screen-sm-down {
    display: block;
    // float: right;
    // margin-top: 1%;
    position: absolute;
    top: 5px;
    right: 5px;

    button {
      background: transparent;
      &:focus {
        outline: 1px dashed #777;
      }

      .bar {
        transform-style: preserve-3d;
        transition: all .3s ease-out;
        display: block;
        margin-top: 3px;
        height: 2px;
        width: 25px;
        background: #ccc;
        opacity: 1;

        &:first-child {
          margin-top: 0;
        }
      }

      &.open {
        .bar {
          &:nth-child(1) {
            transform: rotate(45deg)
                       translate(5px, 6px);
          }

          &:nth-child(2),
          &:nth-child(3) {
            opacity: 0;
            transform: translateX(-15px);
          }

          &:nth-child(4) {
            transform: rotate(-45deg)
                       translate(5px, -6px);
          }
        }
      }
    }
  }
}

header#site-header {
  background: $dark-bg-color;
  border-bottom: 6px solid $subnav-bg-color;

  .site-wrap {
    position: relative;
  }

  .site-logo {
    vertical-align: middle;
    display: inline-block;
    margin: 0;
    position: relative;
    line-height: 1;

    a {
      display: block;
      opacity: .7;
      vertical-align: middle;
      transition: opacity .2s ease-in;
      &:hover {
        opacity: 1;
      }
      img {
        width: 124px;
        height: 28px;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  #site-search {
    float: right;
    vertical-align: middle;
    display: inline-block;
    margin-top: 5px;
    color: $light-grey-text;
    i {
      position: relative;
      z-index: 1;
    }
    input {
      position: relative;
      z-index: 2;
      vertical-align: middle;
      display: inline-block;
      margin-top: -3px;
      background: transparent;
      border: 1px solid transparent;
      border-radius: $border-radius;
      margin-right: -25px;
      @include font-size($txt-xxs);
      transition: all .2s ease-in;
      width: 120px;
      color: $light-grey-text;
      padding: $spacing-unit/2;
      &:focus {
        width: 300px;
        background: $subnav-bg-color;
      }
    }

    @include screen-md-down {
      display: none;
    }
  }

  ul.topmenu {
    margin: 0;
    display: inline-block;
    vertical-align: middle;

    li {
      vertical-align: middle;
      display: block;
      float: left;
      position: relative;

      &:first-of-type {
        display: none;
      }

      &.is-current-page {
        a {
          background: $subnav-bg-color;
          padding: 20px 12px;
          margin: -15px 0 -15px 0;
          opacity: 1;
/*
          box-shadow: inset 0 30px 30px -15px rgba(0,0,0,.4),
                      inset 0 15px 5px -15px rgba(0,0,0,.2),
                      inset 0 15px 5px -15px rgba(0,0,0,.2);
          transition: box-shadow .2s ease-in;
*/
          &:hover {
/*
            box-shadow: inset 0px 20px 50px -50px rgba(0,0,0,0.5),
                        inset 0 0 0 transparent,
                        inset 0 0 0 transparent,
                        inset 0 0 0 transparent;
*/
          }
        }
      }

      a {
        display: block;
        padding: 5px 12px;
        color: white;
        opacity: .7;
        text-decoration:none;
        transition: .2s opacity ease-in;
        &:hover {
          opacity: 1;
        }
      }
    }

    @include screen-sm-down {
      position: relative;
      display: none;
      background: $dark-bg-color;
      padding: 0;
      margin-top: $spacing-unit;

      &.open {
        display: block;
      }
      &.js.open {
        display: none;
      }

      li {
        float: none;
        &.is-current-page {
          a {
            padding: 5px 12px;
            margin: 0;
          }
        }
      }
    }
  }

  .submenu-wrapper {
    padding-top: 3px;
    background: $subnav-bg-color;
    position: relative;

    & .site-wrap {
      white-space: nowrap;
      overflow-x: auto;
    }

    div[id^='submenu-scroll-overflow'] {
      display: none;
    }

    @include screen-sm-down {
      div[id^='submenu-scroll-overflow'] {
        z-index: 30;
        display: block;
        position: absolute;
        width: 30px;
        height: 100%;
        content: ' ';
        background: rgba(85,85,85,1);
        background: linear-gradient(to right, rgba(85,85,85,1) 22%,
                                              rgba(85,85,85,0.73) 43%,
                                              rgba(85,85,85,0) 100%);
      }
      div[id='submenu-scroll-overflow-left'] {
        display: none;
      }
      div[id='submenu-scroll-overflow-right'] {
        right: 0;
        transform: rotate(180deg);
      }
    }
  }

  ul.submenu {
    list-style: none;
    margin: 0 0 0 0px;
    padding: 0;

    li {
      display: inline-block;

      &.is-current-page {
        a {
          color: #f5f5f5;
        }
      }

      &:first-child {
        a:before {
          content: none;
          float: none;
          margin: 0;
        }
      }

      &:last-child {
        margin-right: $spacing-unit*2;
      }
    }

    a {
      display: block;
      vertical-align: middle;
      padding: 4px 12px 4px 0;
      color: #bbb;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: bold;
      @include font-size($txt-xxs);

      &:before {
        content: '•';
        display: block;
        float: left;
        margin-top: 0px;
        margin-right: 12px;
        vertical-align: middle;
        color: #888;
      }
    }
  }

  @include clearfix();
}

aside {
  .inner {
    font-size: smaller;
    transition: opacity .2s ease-in;
    padding: $site-padding-width*2;
    background: #f2f2f2;
    border-radius: $border-radius;
    @include screen-sm-down {
      padding: $site-padding-width;
    }

    .component {
      &:first-child {
        h1,
        h2,
        h3,
        p,
        ul,
        .header {
          &:first-of-type {
            margin-top: 0;
          }
        }
      }
    }
  }

  .bare-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}


footer {
  position: absolute;
  background: $dark-bg-color;
  color: #888;
  width: 100%;
  bottom: 0;

  &.fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  #pike-logo-footer {
    height: 20px;
    width: auto;
  }
}

@mixin bannerbg($colorA, $colorB) {
  background: linear-gradient(-135deg, $colorA 0%,
                                       $colorA 17%,
                                       $colorB 87%,
                                       $colorB 100%);

  .download-button {
    border-color: $colorA;

    &:after {
      color: $colorA;
    }
  }
}

.banner {
  background: $light-bg-color;
  background: linear-gradient(-135deg, $light-bg-gradient 0%,
                                       $light-bg-gradient 17%,
                                       $light-bg-color 87%,
                                       $light-bg-color 100%);
  transition: background 2s ease-in-out;

  &.alt0 {
    @include bannerbg(#EED7C5, #C7F2A7);
  }

  &.alt1 {
    @include bannerbg(#C1DFF0, #EFE9AE);
  }

  &.alt2 {
    @include bannerbg(#FF928B, #81F4E1);
  }

  &.alt3 {
    @include bannerbg(#FF928B, #D1F5BE);
  }

  &.alt4 {
    @include bannerbg(#BAE1FF, #E5FCFF);
  }

  &.alt5 {
    @include bannerbg(#B41616, #DD6464);

    .picture-component {
      color: white;

      h2 {
        color: white;
      }
    }

    .href-component {
      a {
        color: white;
        opacity: .7;
      }
    }
  }

  padding-top: $site-padding-width*2;
  padding-bottom: $site-padding-width*2;

  @include screen-sm-down {
    background: linear-gradient(-0deg, $light-bg-gradient 0%,
                                         $light-bg-gradient 17%,
                                         $light-bg-color 87%,
                                         $light-bg-color 100%);
    padding-top: $site-padding-width;
    padding-bottom: $site-padding-width;

    .col-sm-4 {
      &:last-child {
        display: none;
      }
    }
  }

  .download-button {
    border-color: $light-bg-gradient;
    border-radius: $border-radius;
    background-color: rgba(255,255,255,.4);
    transition: background-color .2s ease-in;

    &:hover {
      background-color: rgba(255,255,255,.7);
    }
    &:after {
      color: $light-bg-gradient;
    }
  }

  h2 {
    color: $dark-bg-color;
  }
}

.download-button {
  position: relative;
  padding: $site-padding-width;
  border: 1px solid #ddd;
  margin-bottom: $spacing-unit;
  overflow: hidden;
  background: #fff;
  text-align: left;

  @include screen-sm-down {
    display: none;
  }

  a {
    text-decoration: none;
    display: block;

    line-height: 1;


    &.pike {
      padding: $spacing-unit/2;
      @include font-size($txt-xl);
      font-weight: bold;
      z-index: 1;
      position: relative;
      .version {
        color: #555;
      }
      .download {
        font-weight: normal;
        @include font-size($txt-xs);
        display: block;
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: left;
    @include font-size($txt-xxs);

    li {
      display: inline-block;
      position: relative;
      z-index: 1;
      a {
        padding: 6px $spacing-unit/2;
      }
    }
  }

  &:after {
    font-size: 18rem;
    font-family: FontAwesome;
    content: '\f0ed';
    position: absolute;
    top: -20%;
    vertical-align: middle;
    right: -2%;
    line-height: 1;
    color: #ddd;

    // @include screen-sp-down {
    //   font-size: 14vw;
    //   top: -10%;
    // }
    // @include screen-md-down {
    //   font-size: 10vw;
    //   top: 4%;
    //   right: 2%;
    // }
  }

  &.mac:after {
    content: '\f179';
  }
  &.win:after {
    content: '\f17a';
    right: -6%;
  }
  &.linux:after {
    content: '\f17c';
    right: -8%;
  }
}

aside {
  .download-button {
  }
}

[data-href] {
  cursor: pointer;
}

.align-center {
  text-align: center;
}
.align-left {
  text-align: left;
}
.align-right {
  text-align: right;

  @include screen-sm-down {
    text-align: left;
  }
}

.github-contrib {
  display: inline-block;
  text-decoration: none;
  padding: $spacing-unit;
  padding-right:0;
  line-height: 1.2;
  float: right;
  @include font-size($txt-xs);
  vertical-align: middle;
  color: #999;
  margin-top: -$spacing-unit/1.8;
  margin-bottom: $spacing-unit/2;

  &:after {
    line-height: 1.2;
    content: '\f09b';
    font-family: FontAwesome;
    margin-left: $spacing-unit/2;
    display: inline-block;
    vertical-align: middle;
    @include font-size($txt-xl);
  }

  @include screen-sm-down {
    display: none;
  }
}


p code, p tt {
  color: $complementary-color;
  background: #f2f2f2;
  padding: 2px 4px;
  font-size: 1.6rem;
  border-radius: $border-radius;
  vertical-align: middle;
}

pre {
  overflow: auto;
  padding: $spacing-unit*1.5;
  background: #f2f2f2;
  border-radius: $border-radius;
  margin-bottom: $spacing-unit;
}

div[class^='col-'] {
  & + div[class^='col-'] {
    @include screen-sm-down {
      margin-top: $spacing-unit*2;
    }
  }
}

div[class^='col-md'] {
  & + div[class^='col-md'] {
    @include screen-md-down {
      margin-top: $spacing-unit*2;
    }
  }
}

footer {
  div[class^='col-'] {
    & + div[class^='col-'] {
      @include screen-sm-down {
        margin-top: 0;
      }
    }
  }
}

.columns {
  @include columns(5);
  &-4 {
    @include columns(4);
  }
  &-3 {
    @include columns(3);
  }
  &-2 {
    @include columns(2);
  }
}
