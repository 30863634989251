
ul[role=tablist] {
  list-style: none;
  padding: 0;
  padding-left: $spacing-unit;
  border-bottom: 1px solid $default-link-color;

  li {
    position: relative;
    display: inline-block;
    margin-right: -1px;

    button {
      background: transparent;
      color: $dark-grey;
      border: 1px dashed #ccc;
      border-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.active {
      z-index: 1;
      button {
        background: $default-link-color;
        color: white;
        border: 1px solid $default-link-color;
        border-bottom: 0;
      }
    }
  }
}

[role=tabpanel] {
  display: none;

  &[aria-hidden='false'] {
    display: block;
  }
}
