ul, ol {
  &.nolist,
  &.linklist,
  &.file-list,
  &.latest-user-comments {
    list-style: none;
    padding-left: 0;
  }
  &.linklist {
    li {
      margin-bottom: $spacing-unit / 2;
    }
    a {
      text-decoration: none;
      display: block;
      &:before {
        font-family: FontAwesome;
        content: '\f138';
        margin-right: $spacing-unit/2;
      }
    }
  }
  &.rfc {
    li {
      margin-bottom: $spacing-unit/2;
      .td {
        padding-right: $spacing-unit/2;
      }
    }
  }
  &.latest-user-comments {
    li {
      margin-bottom: $spacing-unit/2;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        text-decoration: none;
      }

      div {
        text-overflow: ellipsis;
        display: block;
        overflow: hidden;
        white-space: nowrap;
      }

      .byline {
        color: $dark-grey;
        @include font-size($txt-xs);
      }
    }
  }

  &.file-list {
    a {
      text-decoration: none;
      display: block;

      &:before {
        display: inline-block;
        font-family: FontAwesome;
        content: '\f1c9';
        margin-right: $spacing-unit/2;
        width: 20px;
      }
    }

    .gz,
    .zip {
      a {
        &:before {
          content: '\f1c6';
        }
      }
    }

    .pdf {
      a {
        &:before {
          content: '\f1c1';
        }
      }
    }

    .png,
    .jpg,
    .gif {
      a {
        &:before {
          content: '\f1c5';
        }
      }
    }


    .dir {
      a {
        &:before {
          content: '\f07b';
        }
        &:hover {
          &:before {
            content: '\f07c';
          }
        }
      }
    }
  }
}

h2.latest-user-comments {
  // font-weight: normal;
  // font-size: 18px;
  // margin-top: 0;
}
