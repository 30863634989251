.manual {
  .fixme { color: #a00; }
  .expr { color: #8E3DD3; }
  .reference { color: #777; }
  a.reference, a.reference:visited { color: #0087cc; }
  .modifier { color: purple; font-weight: bold; }
  .datatype { color: purple; font-weight: bold; }
  .variable, .constant, .typedef, .inherit, .directive {
    color: #F000F0;
    font-weight: bold;
  }
  .parameter, .argument { color: #0087cc;  }
  .method { color: #000066; }
  .key { color: green; }

  .head--doc { font-weight: bold; color: #777; }
  .homogen--type {
    + .homogen--name {
      margin-left: $spacing-unit;
      color: #930;
    }
  }
  .homogen--name { font-size: large; }
  .group--doc { margin-bottom: 0; }
  .group--doc + hr { margin-top: 10px; }
  dd[class^='body--'],
  dd.example,
  dt[class^='head--'] + dd {
    max-width: 100%;
    overflow-x: auto;
  }
  .inheritance-graph {
    position: relative;
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
    padding-left: 40px;
  }
  table.box {
    width: 100%;
    color: #555;
    margin-top: $spacing-unit;
    margin-bottom: $spacing-unit;
    border-collapse: collapse;
    border: 1px solid #ddd;
    border-radius: $border-radius;

    pre {
      padding: 0;
      background: transparent;
    }
  }
  table.box tr {
    border-bottom: 1px dashed #ccc;
  }
  table.box td {
    vertical-align: top;
    padding: 10px 12px;
    border-left: 1px dashed #ccc;
  }
  table.box td *:first-child { margin-top: 0; }
  table.box td *:last-child { margin-bottom: 0; }
  table.box table.box { width: 100%; }

  dt {
    font-weight: bold;

    & + dd {
      & > hr:first-of-type {
        display: none;
      }
    }

    h2 {
      &.header {
        background: #f2f2f2;
        padding: $site-padding-width;
        border-bottom: 0;
      }
    }
  }

  dd {
    margin-left: $spacing-unit*2;
  }

  hr {
    border: none;
    height: 1px;
    background: #ddd;
    color: #ddd;
    margin-bottom: $spacing-unit*2;
  }
}
